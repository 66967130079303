import React from 'react';
import './Header.css';

export default () => (
  <>
    <div className="burger-menu--dash" />
    <div className="burger-menu--dash" />
    <div className="burger-menu--dash" />
  </>
);
